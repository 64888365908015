import { useAuth } from 'hooks/Auth';
import React, { createContext, useContext } from 'react';
import TagManager from 'react-gtm-module';

export enum ButtonEvents {
  PEGAR_CUPOM = 'button_click_pegarcupom',
  REMOVE_CUPOM = 'button_click_removecupom',
  CLEAR_ALL_CUPOM_LIST = 'button_click_clearallcupomlist',
  VIEW_CUPOM_LIST = 'button_click_viewcupomlist',
  GET_MORE_CUPOMS = 'button_click_getmorecupoms',
  VIEW_CUPOM = 'button_click_viewcupom',
  HEADER_ACTION = 'button_click_headeraction',
  LOGIN_ACTION = 'button_click_login',
  LOGOUT_ACTION = 'button_click_logout',
  LOGIN_ACTION_PHONE = 'button_click_login_phone',
  LOGIN_ACTION_PHONE_CONTINUAR = 'button_click_login_phone_continuar',
  FINISH_LOGIN_ACTION_SMS = 'button_click_login_sms_continuar',
  REGISTER_CONTINUE = 'button_click_register_user',
  LOGIN_ACTION_TROUBLESHOOTING = 'button_click_login_troubleshooting',
  CALL_TO_US = 'button_click_calltous',
  PRODUCT_VIEW = 'button_click_product_view',
  VIEW_PERFIL = 'button_click_view_perfil',
  PERMISSOES_PERFIL = 'button_click_permissoes_perfil',
  TABELA_NUTRICIONAL = 'button_click_tabela_nutricional',
  FILTER_CATEGORY = 'button_click_filter_category',
  TERMOS_CONDICOES = 'button_click_termos_condicoes',
}

export enum ExtraEvents {
  SUCCESS_LOGIN = 'successfully_logged_in',
  SUCCESS_REGISTRED = 'successfully_registred_in',
}

interface ITagManagerContextType {
  sendPageViewEvent: (
    eventName: string,
    pagePath: string,
    pageTitle: string,
  ) => void;
  sendButtonClickEvent: (event: ButtonEvents, eventData?: object) => void;
  sendExtraEvents: (event: ExtraEvents, eventData?: object) => void;
}

const TagManagerContext = createContext<ITagManagerContextType | undefined>(
  undefined,
);

interface ITagManagerProviderProps {
  children: React.ReactNode;
}

export const TagManagerProvider: React.FC<ITagManagerProviderProps> = ({
  children,
}: ITagManagerProviderProps) => {
  const { customerId } = useAuth();

  const sendPageViewEvent = (pagePath: string, pageTitle: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath,
        pageTitle,
        userId: customerId !== null ? customerId : undefined,
      },
    });
  };

  const sendButtonClickEvent = (event: ButtonEvents, eventData?: object) => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...eventData,
        userId: customerId !== null ? customerId : undefined,
      },
    });
  };

  const sendExtraEvents = (event: ExtraEvents, eventData?: object) => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...eventData,
        userId: customerId !== null ? customerId : undefined,
      },
    });
  };

  const contextValue: ITagManagerContextType = {
    sendPageViewEvent,
    sendButtonClickEvent,
    sendExtraEvents,
  };

  return (
    <TagManagerContext.Provider value={contextValue}>
      {children}
    </TagManagerContext.Provider>
  );
};

export const useTagManager = (): ITagManagerContextType => {
  const context = useContext(TagManagerContext);
  if (!context) {
    throw new Error('useTagManager must be used within a TagManagerProvider');
  }
  return context;
};
