import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { parseISO, isBefore, isAfter } from 'date-fns';

import defaultRemoteConfig from 'defaultRemoteConfig';
import { useAuth } from 'hooks/Auth';
import { api, menuApi } from 'services';
import firebase from 'services/firebase';

const CouponsContext = createContext({});

interface ICouponsProviderProps {
  children: any;
}

interface ICupom {
  id: number;
  nome: string;
  descricao: string;
  codigo: string;
  categoria: {
    id: number;
    nome: string;
  } | null;
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  domingo: boolean;
  start_cupom: string | null;
  end_cupom: string | null;
}

const CouponsProvider = ({ children }: ICouponsProviderProps) => {
  const { customerId, user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingRemoteConfig, setLoadingRemoteConfig] = useState(false);

  const [remoteConfig, setRemoteConfig] = useState(defaultRemoteConfig);

  const [chosenCoupon, setChosenCoupon] = useState(() => {
    const chosenCouponDataStorage = localStorage.getItem(
      '@Popeyes.chosenCoupon.v2',
    );
    const chosenCouponData = JSON.parse(chosenCouponDataStorage);
    return chosenCouponData;
  });

  const [cartCoupon, setCartCoupon] = useState(() => {
    const cartDataStorage = localStorage.getItem('@Popeyes.cart.v2');
    const cart = JSON.parse(cartDataStorage);
    return cart || [];
  });

  const [categories, setCategories] = useState(() => {
    const categoriesDataStorage = localStorage.getItem(
      '@Popeyes.categories.v2',
    );
    const categoriesData = JSON.parse(categoriesDataStorage);
    return categoriesData || [];
  });

  const [coupons, setCoupons] = useState(() => {
    const couponsDataStorage = localStorage.getItem('@Popeyes.coupons.v2.v2');
    const couponsData = JSON.parse(couponsDataStorage);
    return couponsData || [];
  });

  const [highlightsCoupons, setHighlightsCoupons] = useState(() => {
    const highlightsCouponsDataStorage = localStorage.getItem(
      '@Popeyes.highlightsCoupons.v2',
    );
    const highlightsCouponsData = JSON.parse(highlightsCouponsDataStorage);
    return highlightsCouponsData || [];
  });

  const setUpRemoteConfig = useCallback(async () => {
    setLoadingRemoteConfig(true);

    const remoteConfigFirebase = firebase.remoteConfig();
    remoteConfigFirebase
      .fetchAndActivate()
      .then(() => {
        const remoteCoupons = JSON.parse(
          remoteConfigFirebase.getString('cupons'),
        );
        const remoteCouponsCart = JSON.parse(
          remoteConfigFirebase.getString('cupons_cart'),
        );
        const remoteCouponsDetail = JSON.parse(
          remoteConfigFirebase.getString('cupons_detail'),
        );

        remoteCouponsCart.validade_text.text += ` (${new Date().toLocaleDateString(
          'pt-BR',
        )})`;

        const remoteConfigData: any = {
          cupons: remoteCoupons,
          cupons_detail: remoteCouponsDetail,
          cupons_cart: remoteCouponsCart,
        };

        setRemoteConfig(remoteConfigData);
        setLoadingRemoteConfig(false);
      })
      .catch(() => {
        setLoadingRemoteConfig(false);
      });
  }, [remoteConfig, setRemoteConfig, setLoadingRemoteConfig]);

  const onRequestMyCart = useCallback(
    async (couponsItens: any) => {
      setLoadingCart(true);
      if (customerId) {
        if (user.fakeUser) {
          const cart = JSON.parse(
            localStorage.getItem('@Popeyes.cart.v2') || '[]',
          );
          return cart;
        }
        try {
          const response = await api.get('menu/v0.1/cuponsCart', {
            params: { customer_id: customerId },
          });

          const quantyTotal = response.data;

          const filteredCoupons = couponsItens.filter((coupon: any) =>
            quantyTotal.find(
              (couponGot: any) => couponGot.cupom_id === coupon.id,
            ),
          );

          setLoadingCart(false);
          return filteredCoupons;
        } catch (err: any) {
          if (err.response.status === 404 || err.response.status === 401) {
            setLoadingCart(false);
            return [];
          }
          setLoadingCart(false);
          return null;
        }
      } else {
        setLoadingCart(false);
        return null;
      }
    },
    [customerId, setLoadingCart],
  );

  function isValidCupomDate(cupom: ICupom, currentDate: Date) {
    const daysOfWeek = [
      'domingo',
      'segunda',
      'terca',
      'quarta',
      'quinta',
      'sexta',
      'sabado',
    ] as const;

    const currentDayOfWeek = currentDate.getDay();
    const currentDay = daysOfWeek[currentDayOfWeek];

    const valid = cupom[`${currentDay}`];

    if (valid === undefined) return true;

    return valid;
  }

  const onRequestCoupons = useCallback(async () => {
    setLoading(true);
    try {
      const response = await menuApi.get('/listar/cupons');

      if (response.data) {
        const { cupons } = response.data;

        const categoriesList = cupons.map((coupon: ICupom) => {
          return coupon.categoria;
        });

        const uniqueCategories: any = [];
        categoriesList
          .filter((category: any) => category != null)
          .forEach((category: any) => {
            if (
              !uniqueCategories.some(
                (uniqueCategory: any) => uniqueCategory.id === category.id,
              )
            ) {
              uniqueCategories.push({
                id: category.id,
                order: 100,
                name: category.nome,
                selected: false,
              });
            }
          });

        uniqueCategories.sort((a: any, b: any) => a.order - b.order);

        try {
          const myCart = await onRequestMyCart(cupons); // TODO: review this
          if (myCart) {
            localStorage.setItem('@Popeyes.cart.v2', JSON.stringify(myCart));
            setCartCoupon(myCart);
          }
        } catch (error) {
          console.log(error);
        }

        // localStorage.setItem('@Popeyes.coupons.v2.v2', JSON.stringify(cupons));
        // localStorage.setItem(
        //   '@Popeyes.categories',
        //   JSON.stringify(uniqueCategories),
        // );

        const currentDate = new Date();

        const filtredCuponsPerDate = cupons
          .filter((cupon: ICupom) => isValidCupomDate(cupon, currentDate))
          .filter((cupon: ICupom) => {
            const startDate = cupon.start_cupom;
            const endDate = cupon.end_cupom;

            if (startDate != null && endDate != null) {
              const startFnsDate = parseISO(startDate);
              const endFnsDate = parseISO(endDate);

              return (
                isAfter(currentDate, startFnsDate) &&
                isBefore(currentDate, endFnsDate)
              );
            }

            if (startDate != null) {
              const startFnsDate = parseISO(startDate);

              return isAfter(currentDate, startFnsDate);
            }

            if (endDate != null) {
              const endFnsDate = parseISO(endDate);

              return isBefore(currentDate, endFnsDate);
            }

            return startDate == null && endDate == null;
          });

        setCoupons(filtredCuponsPerDate);
        setCategories(uniqueCategories);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [
    customerId,
    setCoupons,
    setCartCoupon,
    setLoading,
    setCategories,
    setHighlightsCoupons,
  ]);

  const updateMyCart = useCallback(async () => {
    const myCart = await onRequestMyCart(coupons);
    if (myCart) {
      localStorage.setItem('@Popeyes.cart.v2', JSON.stringify(myCart));
      setCartCoupon(myCart);
    }
  }, [coupons, setCartCoupon]);

  useEffect(() => {
    setUpRemoteConfig();
    onRequestCoupons();
  }, []);

  const onUpdateCategories = useCallback(
    async (newCategories: any) => {
      localStorage.setItem(
        '@Popeyes.categories.v2',
        JSON.stringify(newCategories),
      );
      setCategories(newCategories);
    },
    [setCategories],
  );

  const onChoseCoupon = useCallback(
    async (couponSelected: any) => {
      console.log(couponSelected);
      localStorage.setItem(
        '@Popeyes.chosenCoupon.v2',
        JSON.stringify(couponSelected),
      );
      setChosenCoupon(couponSelected);
    },
    [setChosenCoupon],
  );

  const onDeleteCoupon = useCallback(
    async (coupon: any) => {
      try {
        if (!user.fakeUser) {
          const bodyParams = {
            customer_id: customerId,
            cupom_id: coupon.id,
          };

          await api.delete('menu/v0.1/cuponsCart', { data: bodyParams });
        } else {
          const cart = JSON.parse(
            localStorage.getItem('@Popeyes.cart.v2') || '[]',
          );
          const newCart = cart.filter(
            (couponItem: any) => couponItem.id !== coupon.id,
          );
          localStorage.setItem('@Popeyes.cart.v2', JSON.stringify(newCart));
        }
      } catch (err) {
        console.log(err);
      }
    },
    [customerId],
  );

  const onGetCoupon = useCallback(
    async (coupon: any) => {
      setLoading(true);

      try {
        if (!user.fakeUser) {
          const bodyParams = {
            customer_id: parseInt(customerId, 10),
            cupom_id: coupon.id,
            replay_cupom: !!coupon.replay_cupom,
          };

          await api.post('menu/v0.1/cuponsCart', bodyParams);
        } else {
          const cart = JSON.parse(
            localStorage.getItem('@Popeyes.cart.v2') || '[]',
          );
          cart.push(coupon);
          localStorage.setItem('@Popeyes.cart.v2', JSON.stringify(cart));
        }

        await updateMyCart();
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [customerId, updateMyCart, setLoading],
  );

  return (
    <CouponsContext.Provider
      value={{
        coupons,
        highlightsCoupons,
        cartCoupon,
        categories,
        chosenCoupon,
        remoteConfig,
        couponsRemoteConfig: remoteConfig.cupons,
        couponDetailRemoteConfig: remoteConfig.cupons_detail,
        cartRemoteConfig: remoteConfig.cupons_cart,
        loading,
        loadingCart,
        loadingRemoteConfig,
        onRequestCoupons,
        onUpdateCategories,
        onChoseCoupon,
        onRequestMyCart: updateMyCart,
        onDeleteCoupon,
        onGetCoupon,
      }}
    >
      {children}
    </CouponsContext.Provider>
  );
};

function useCoupons(): any {
  const context = useContext(CouponsContext);
  return context;
}

export { CouponsProvider, useCoupons };
